<template>
	<div class="box" id="pax">
		<div class="row">
			<div class="col-12">
				<div v-if="!addOrEditReady">
					<LoadingSpinner class="col-12" />
				</div>
				<div v-else>
					<h3>{{ $t('pax.caracterisation_pax') }}</h3>

					<b-form-checkbox id="caracterisation_certified" v-model="caracterisation_certified" @change="saveCertified"><label for="caracterisation_certified">{{ $t("pax.caracterisation_certified") }}</label> <font-awesome-icon v-if="processing_certified" :icon="['fal', 'spinner']" spin /></b-form-checkbox>

					<div v-for="critere in criteres" :key="critere.criteres_id" class="critere" id="myGroup">
						<b-button @click="OpenAllCollapse(critere.criteres_id)" v-b-toggle="'critere-' + critere.criteres_id"><span>{{ critere.critereslang_label }}</span> <span v-if="refresh_slider" class="badge badge-pill badge-dark px-2">{{critere.slider}}</span> <font-awesome-icon :icon="['far', 'angle-down']" /><font-awesome-icon :icon="['far', 'angle-up']" /></b-button>
						<b-collapse :id="'critere-' + critere.criteres_id" :ref="'critere-'+critere.criteres_id" >
							<hr class="mt-0">
							<div class="row">
								<div class="col">
									{{ critere.critereslang_moins }}
								</div>
								<div class="col text-right">
									{{ critere.critereslang_plus }}
								</div>
							</div>
							<input type="range" min="1" max="4" step="0.5" list="tickmarks" class="form-range" @input="changeSlider(critere)" v-model="critere.slider"> 
							<datalist id="tickmarks">
								<option value="1" label="0%"></option>
								<option value="2.5" label="50%"></option>
								<option value="4" label="100%"></option>
							</datalist>
							<div class="marks">
								<div class="row no-gutters">
									<div class="col-auto">1</div>
									<div class="col-auto ml-auto">2</div>
									<div class="col-auto ml-auto">3</div>
									<div class="col-auto ml-auto">4</div>
								</div>
							</div>
							<a href="#" @click.prevent="displayHelp(critere.criteres_id, critere.critereslang_label)" class="my-3"><b>{{ $t('pax.aide') }}</b> <font-awesome-icon :icon="['far', 'info-circle']" /></a>
						</b-collapse>
					</div>

					<b-modal dialog-class="modal-pax" ref="modal" hide-footer>
						<template v-slot:modal-title>
							{{ title_modal }}
						</template>

						<div v-if="!modal_loaded">
							<LoadingSpinner class="col-12" />
						</div>

						<div v-else v-html="cleanHTML(content_help)">

						</div>
					</b-modal>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Navigation from "@/mixins/Navigation.js"
	import PaxMixin from "@/mixins/Pax.js"
	import Tools from "@/mixins/Tools.js"
	import _debounce from 'lodash/debounce'
	import xss from 'xss'

	export default {
		name: "HorsePax",
		props: ['horse_id'],
		mixins: [Navigation, PaxMixin, Tools],
		data () {
			return {
				addOrEditReady: false,
				criteres: [],
				lang: 'fr',
				title_modal: '',
				modal_loaded: false,
				content_help: '',
				debouncedSend: _debounce(this.saveCritereMare, 300),
				refresh_slider: true,
				caracterisation_certified: false,
				processing_certified: false
			}
		},
		created() {
			this.init_component()
		},
		methods: {
			async init_component() {
				this.addOrEditReady = false

				this.criteres = await this.getCriteres(Vue.i18n.locale())
				
				for (let index = 0; index < this.criteres.length; index++) {
					const element = this.criteres[index];

					element['critereslang_label'] = element.criteres_lang[0].critereslang_label
					element['critereslang_moins'] = element.criteres_lang[0].critereslang_moins
					element['critereslang_plus'] = element.criteres_lang[0].critereslang_plus
					element['critereslang_description'] = element.criteres_lang[0].critereslang_description
				}

				const criteres_mare = await this.getCaracterisationByHorse(this.horse_id)
				criteres_mare.forEach(c_mare => {
					const critere_index = this.criteres.findIndex((c) => c.criteres_id == c_mare.caracterisation_criteres)
					if(critere_index > -1) {
						this.criteres[critere_index].slider = c_mare.valeur
					}
				})

				this.caracterisation_certified = criteres_mare.filter(c_mare => c_mare.caracterisation_certified).length == criteres_mare.length && criteres_mare.length > 0

				this.addOrEditReady = true
			},

			changeSlider(critere) {
				this.refresh_slider = false
				this.debouncedSend(critere)
				this.refresh_slider = true
			},

			saveCritereMare(critere) {
				this.saveCaracterisationByHorse(this.horse_id, critere.criteres_id, critere.slider, this.caracterisation_certified)
			},

			async saveCertified(val) {
				if(this.criteres.length == 0) {
					return false
				}
				this.processing_certified = true
				await this.saveCaracterisationByHorse(this.horse_id, this.criteres[0].criteres_id, this.criteres[0].slider, val)
				this.processing_certified = false
			},

			OpenAllCollapse(criteres_id) {
				for (var i = 0; i < this.criteres.length; i++) {
					if(criteres_id != this.criteres[i].criteres_id) {
						this.$refs["critere-" + this.criteres[i].criteres_id][0].$data.show = false
					}
				}
      		},

			async displayHelp(criteres_id, critere_label) {
				this.title_modal = critere_label
				this.$refs["modal"].show()
				this.modal_loaded = false

				let current_critere = this.criteres.find(elem => elem.criteres_id == criteres_id)
				this.content_help = current_critere.critereslang_description
				
				this.modal_loaded = true
			},
			cleanHTML(input) {
				return xss(input)
			}	
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
		}
	}

</script>